import React from "react";
import classes from "./Contacts.module.css";
import {NAIL_SHOP_INFO} from "../../utils/Const";
import {images} from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

const Contacts = (props) => {
    return (
        <div ref={props?.contactsRef} className={classes.contacts}>
            <div className={classes.contactsContainer}>
                <h1>{"Contact Us"}</h1>

                <a href={`tel:${NAIL_SHOP_INFO.tel}`}>{`Hotline: ${NAIL_SHOP_INFO.phone}\n\n`}</a>
                <a href={`mailto:${NAIL_SHOP_INFO.email}`}>{`Email: ${NAIL_SHOP_INFO.email}`}</a>

                <div>
                    <a
                        target={"_blank"}
                        href={NAIL_SHOP_INFO.instagram}
                        rel="noreferrer">
                        <img
                            className={classes.contactsContainerImg}
                            alt={"insta"}
                            src={images.instaIcon}
                        />
                    </a>
                    <a target={"_blank"} href={NAIL_SHOP_INFO.facebook} rel="noreferrer">
                        <img className={classes.contactsContainerImg} alt={"facebook"} src={images.fbIcon}/>
                    </a>
                </div>
                <BookNowBtn isShowArrow={false} style={classes.bookNowBtn}/>
            </div>

            <iframe
                title="google-map"
                className={classes.iframe}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2237.4269560679845!2d-4.3289587!3d55.8899543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48884500180de907%3A0x3f3ac0803081d2c8!2sLuxury%20Nails%20%26%20Spa%20Anniesland!5e0!3m2!1sen!2s!4v1708528092787!5m2!1sen!2s"
                allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    );
};

export default Contacts

import React, { useContext, useEffect, useState } from "react";
import classes from "./Services.module.css";
import AuthContext from "../../store/auth-context";
import Helpers from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";
import { SERVICES } from "../../utils/Const";
import { images } from "../../assets/img";
import RemoteConfig from "../../services/RemoteConfig";

const ServiceItem = (item, index, onPress) => {
  const { name, image, description } = item || {};
  return (
    <div
      onClick={onPress?.(item)}
      key={`${name + index}`}
      className={classes.serviceItem}
    >
      <div className={`${classes.roundDiv}`}>
        <img alt={"nail"} src={image} />
      </div>

      <h3>{name}</h3>
      <h4 className={classes["change-color-on-hover"]}>{description}</h4>
    </div>
  );
};

const PricingItem = ({ name, price, price2 }) => {
  return (
    <>
      <div className={classes.pricing}>
        <h3 className={classes.alignLeft}>{name}</h3>
        <h3 className={classes.alignCenter}>{`£${price}`}</h3>
        {price2 ? (
          <h3 className={classes.alignCenter}>{`${
            price2 === " " ? "" : "£"
          }${price2}`}</h3>
        ) : null}
      </div>
    </>
  );
};

const PricingItem2 = ({ items }) => {
  return (
    <div className={classes.rowItem}>
      {items.map((item) => {
        return (
          <div key={item?.name} className={classes.pricingItem2}>
            <h3 className={classes.alignLeft}>{item?.name}</h3>
            <h3 className={classes.alignCenter}>{`${
              item?.isExtra ? "extra" : ""
            } ${item?.price === " " ? "" : "£"}${item?.price}`}</h3>
          </div>
        );
      })}
    </div>
  );
};

const Services = (props) => {
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [pricingImg,setPricingImg] = useState(images.pricing);
  const navigate = useNavigate();

  useEffect(() => {
    const introduction_image = RemoteConfig.getConfigValue(
      "pricing_image"
    );

    console.log('aioujsjhdfsd',introduction_image );

    introduction_image && setPricingImg(introduction_image)

  }, [isFetchActivateRemoteConfig]);

  const onPress = (item) => () => {
    // navigate(`/service-details/${item?.id}`, {state: {...item}});
  };

  return (
    <div ref={props?.serviceRef} className={classes.wrapper}>
      <h1>Our Services</h1>

            <div className={classes.container}>
                {Helpers.isArrayAvailable(SERVICES)
                    ? SERVICES.map((item, index) => {
                        return ServiceItem(item, index, onPress);
                    })
                    : null}
            </div>

            <img alt={'pricing'} src={pricingImg} className={classes.pricingImage}/>

      {/* <div className={classes.menuPricing}>
        <div className={classes.menuTitle}>
          <h2 className={classes.alignLeft}>{"NAILS ENHANCEMENT"}</h2>
          <h2 className={classes.alignCenter}>Full Set</h2>
          <h2 className={classes.alignCenter}>Infill</h2>
        </div>
        <PricingItem name={"Fullset Acrylic With Gel"} price={30} price2={28} />
        <PricingItem name={"Fullset Powder Colours"} price={30} price2={28} />
        <PricingItem name={"BIAB / Builder Gel"} price={28} price2={28} />
        <PricingItem name={"Fullset Acrylic Ombre"} price={32} price2={27} />
        <PricingItem name={"Pink & White"} price={33} price2={28} />
      </div>

      <div className={classes.menuPricing}>
        <div className={classes.menuTitle}>
          <h2 className={classes.alignLeft}>
            {"MANICURE & PEDICURE TREATMENTS"}
          </h2>
        </div>
        <PricingItem name={"Gel / Shellac Hands"} price={18} />
        <PricingItem name={"Gel / Shellac Toes"} price={20} />
        <PricingItem name={"Manicure"} price={15} />
        <PricingItem name={"Spa Pedicure"} price={30} />

        <PricingItem2
                    items={[{name: 'Gel / Shellac Hands', price: 18}, {name: 'Shellac on Toes', price: 20}]}
                />
                <PricingItem2
                    items={[{name: 'Manicure', price: 15}, {name: 'Spa Pedicure', price: 30}]}
                />
      </div>

      <div className={classes.menuPricing}>
        <div className={classes.menuTitle}>
          <h2 className={classes.alignLeft}>{"Others"}</h2>
        </div>

        <PricingItem name={"Take off Acrylic"} price={10} />
        <PricingItem name={"Take off BIAB"} price={10} />
        <PricingItem name={"Take off shellac hands & toes"} price={8} />
        <PricingItem name={"Diamonds (10)"} price={3} />
        <PricingItem name={"Nails are extra"} price={8} />

        <PricingItem2
                    items={[{name: 'Manicure', price: 15}, {name: 'Manicure with Normal Polish', price: 17}]}
                />
                <PricingItem2
                    items={[{name: 'Manicure with Shellac', price: 27}, {name: 'Spa Pedicure', price: 25}]}
                />
                <PricingItem2
                    items={[{name: 'Spa Pedicure with Shellac', price: 32}, {
                        name: 'Spa Pedicure with Normal Polish',
                        price: 28
                    }]}
                />
                <PricingItem2
                    items={[{
                        name: 'Manicure + Spa Pedicure with Shellac',
                        price: 55
                    }, {name: 'Manicure + Spa Pedicure with Normal Polish', price: 43}]}
                />
                <PricingItem2
                    items={[{name: 'Extra French Tips', price: 5}, {name: ' ', price: ' '}]}
                />
      </div> */}


      
    </div>
  );
};

export default Services;

import classes from "./PrimaryBanner.module.css";
import React, { useContext, useEffect, useState, memo } from "react";
import AuthContext from "../../store/auth-context";
import { t } from "../../locales/i18n";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";

const PrimaryBanner = (props) => {
  const {} = props || {};
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [banner, setBanner] = useState(images.castleNailBanner);

  useEffect(() => {
    const primary_banner = RemoteConfig.getConfigValue("primary_banner");
    primary_banner && setBanner(primary_banner);
  }, [isFetchActivateRemoteConfig]);

  return (
    <div className={classes.primaryBanner}>
      <img
        className={classes.primaryBannerImage}
        alt={"Primary Banner"}
        src={banner}
      />
      <div className={classes.textContainer}>
        <h2>The go to salon in Anniesland </h2>
        <BookNowBtn />
      </div>
    </div>
  );
};

export default memo(PrimaryBanner);

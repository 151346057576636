import classes from "./FooterInfo.module.css";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const FooterInfo = (props) => {
  const isMobile = useCheckIsMobile();

  return (
    <div className={classes.container}>
      <div className={classes.columnInfo}>
        <div className={classes.address}>
          <h2>{"Luxury Nails & Spa Anniesland"}</h2>
          <a
            target={"_blank"}
            href={NAIL_SHOP_INFO.ggMapUrl}
            rel="noreferrer"
          >
            {`1632 Great Western Rd, Anniesland,${isMobile ? "" : "\n"}Glasgow G13 1HH, United Kingdom\n\n`}
          </a>
        </div>

        <div className={classes.openingTimeWrapper}>
          <h3>Opening Time</h3>
          <div className={classes.openingTimeContainer}>
            <div className={classes.openingTime}>
              <h3>{"Monday - Wednesday"}</h3>
              <h3>{"09:00 AM - 06:00 PM"}</h3>
            </div>
            <div className={classes.openingTime}>
              <h3>{"Thursday - Saturday"}</h3>
              <h3>{"08:30 AM - 06:30 PM"}</h3>
            </div>
            <div className={classes.openingTime}>
              <h3>Sunday</h3>
              <h3>Closed</h3>
            </div>
          </div>
        </div>

      </div>

      <div className={classes.contactContainer}>
        <h3>Contact Us</h3>
        <a
          href={`tel:${NAIL_SHOP_INFO.tel}`}
        >{`Hotline: ${NAIL_SHOP_INFO.phone}\n\n`}</a>
        <a
          href={`mailto:${NAIL_SHOP_INFO.email}`}
        >{`Email: ${NAIL_SHOP_INFO.email}`}</a>
      </div>

      <div className={classes.columnInfo}>
        <div className={`${classes.contactContainer} ${classes.marginBottom}`}>
          <h3>Follow Us</h3>
          <a
            target={"_blank"}
            href={NAIL_SHOP_INFO.instagram}
            rel="noreferrer"
          >
            <img alt={"insta"} src={images.instaIcon} />
          </a>
          <a
            target={"_blank"}
            href={NAIL_SHOP_INFO.facebook}
            rel="noreferrer"
          >
            <img alt={"insta"} src={images.fbIcon} />
          </a>
        </div>

        <br />



        <div
          className="fb-page"
          data-href={NAIL_SHOP_INFO.facebook}
          data-tabs="timeline"
          data-width={isMobile ? "320" : "300"}
          data-height="100"
          data-small-header="false"
          data-adapt-container-width="false"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite={NAIL_SHOP_INFO.facebook}
            className="fb-xfbml-parse-ignore"
          >
            <a href={NAIL_SHOP_INFO.facebook}>Luxury Nails & Spa Anniesland</a>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;

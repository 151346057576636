import classes from "./Introduction02.module.css";
import React, { useContext, useEffect, useState } from "react";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";

const Introduction02 = (props) => {
  const { introductionRef } = props || {};
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [imagesIntro, setImages] = useState([
    images.nailImg03,
    images.nailImg04,
    images.nailImg05,
  ]);
  const [texts, setTexts] = useState([
    {
      title: "Discover Your Bespoke Nails Bar in Anniesland",
      description:
        "At Luxury Nails & Spa Anniesland, At our salon, we prioritize hygiene, technical expertise, and exceptional customer service to ensure you leave feeling relaxed, pampered, and looking fabulous.Discover our stunning beauty salon in Annieslandm Glasgow and indulge in our fantastic high-quality treatments at affordable prices. Book your appointment today to experience the ultimate in beauty and relaxation.",
    },
    {
      title: "Our Gel Guarantee",
      description:
        "At our nail salon, customer care is our top priority. We strive for every client to leave our salon with the perfect manicure or pedicure and a smile on their face. Our friendly and knowledgeable team of nail technicians has been carefully selected and trained to provide a bespoke, professional experience from start to finish. We are committed to delivering flawless nail treatments every time. If you encounter any issues with your gel manicure or pedicure from our salon, we offer complimentary nail repair. Simply contact our hotline at +44 753 266 8888 (our manager), and our technicians will ensure your nails are restored to their former glory.",
    },
  ]);

  useEffect(() => {
    const introduction_image = RemoteConfig.getConfigValue(
      "introduction2_images"
    );
    const introduction2_texts = RemoteConfig.getConfigValue(
      "introduction2_texts"
    );

    introduction_image &&
      Array.isArray(introduction_image) &&
      introduction_image.length > 0 &&
      setImages(introduction_image);

    introduction2_texts && setTexts(introduction2_texts);
  }, [isFetchActivateRemoteConfig]);

  return (
    <div ref={introductionRef} className={classes.introduction}>
      <div className={classes.rowWrapper}>
        <div className={classes.introTextContainer}>
          {texts?.map((item, idx) => {
            return (
              <div>
                <h2>{item?.title}</h2>
                <p>{item?.description}</p>
              </div>
            );
          })}

          <BookNowBtn />
        </div>

        <div className={classes.imageRow}>
          <img alt={"Intro"} src={imagesIntro[0]} className={classes.tallImg} />
          <div className={classes.wideImgContainer}>
            <img
              alt={"Intro"}
              src={imagesIntro[1]}
              className={classes.wideImg}
            />
            <div className={classes.separator}></div>
            <img
              alt={"Intro"}
              src={imagesIntro[2]}
              className={classes.wideImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction02;

import classes from "./Introduction.module.css";
import React,{useEffect,useContext,useState} from "react";
import {images} from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";

//ABOUT US
const Introduction = (props) => {
    const {introductionRef} = props || {};
    const { isFetchActivateRemoteConfig } = useContext(AuthContext);
    const [image, setImage] = useState(images.nailImg02);
    
    useEffect(() => {
      const introduction_image = RemoteConfig.getConfigValue("introduction_image");
      introduction_image && setImage(introduction_image);
    }, [isFetchActivateRemoteConfig]);

    return (
        <div ref={introductionRef} className={classes.introduction}>
            <div className={classes.introHeader}>
                <h2 className={classes.cursiveText}>Luxury Nails & Spa Anniesland</h2>
            </div>
            <div className={classes.rowWrapper}>
            <img alt={'Intro'} src={ image } className={classes.introImg}/>
            <div className={classes.introTextContainer}>
                <div>
                    <h1>{"LUXURY NAILS & SPA ANNIESLAND\nPROFESSIONALS FOR YOUR EVERY NAIL DESIRE"}</h1>
                    <h3 className={classes.greyIntroTextContainer}>Best Nails Salon in Anniesland - Glasgow for every nail treatment you love</h3>
                </div>
                <p className={classes.greyIntroTextContainer}>Welcome to our brand-new salon in Anniesland, where we bring together experts and experiences you won’t find elsewhere. Located in the heart of Anniesland - Glasgow, our nail salon is staffed with a passionate team eager to create the nails of your dreams. Whether you prefer trusted classics like our classic manicure or desire bespoke nail art, you're in good hands. Experience bespoke nail artistry at one of Anniesland's premier nail salons.</p>
                <p className={classes.greyIntroTextContainer}>Leave your next nail journey in our professional hands. We take pride in being recognized as one of the best nail salons in Anniesland. Our extensive range of treatments has been meticulously crafted to perfection, ensuring healthy and fabulous-looking nails every time!</p>
                <BookNowBtn/>
            </div>
            </div>
        </div>
    );
};

export default Introduction;

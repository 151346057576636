import React, { useEffect, useState } from "react";
import classes from "./Menu.module.css";
import { useNavigate } from "react-router-dom";

const Menu = ({ isClose, introductionRef, serviceRef, contactsRef }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setIsDropdownOpen(isClose);
  }, [isClose]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const onClickMenuItem = (key) => () => {
    switch (key) {
      case "introduction":
        introductionRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "services":
        serviceRef?.current?.scrollIntoView({ behavior: "smooth" });

        break;
      case "contacts":
        contactsRef?.current?.scrollIntoView({ behavior: "smooth" });

        break;
      case "booking":
        navigate("/booking");
        break;
      default:
        break;
    }

    toggleDropdown();
  };

  return (
    <div
      className={`${classes.mobileHeader} ${
        isDropdownOpen ? classes.open : ""
      }`}
    >
      <button className={classes.menuButton} onClick={toggleDropdown}>
        Menu
      </button>
      <div
        className={`${classes.dropdownContent} ${
          isDropdownOpen ? classes.fadeIn : classes.fadeOut
        }`}
      >
        <ul>
          <li onClick={onClickMenuItem("introduction")}> Introduction</li>
          <li onClick={onClickMenuItem("services")}> Services </li>
          <li onClick={onClickMenuItem("booking")}>Booking</li>
          <li onClick={onClickMenuItem("contacts")}>Contacts</li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
